.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
  @extend .d-flex;
}
.align-center {
  display: flex;
  align-items: center;
}
.justify-start {
  display: flex;
  justify-content: flex-start;
}
.align-start {
  display: flex;
  align-items: flex-start;
}
.justify-end {
  display: flex;
  justify-content: flex-end;
}
.align-end {
  display: flex;
  align-items: flex-end;
}
.w-100 {
  width: 100%;
}
.main-div {
  .small-container {
    max-width: 1014px;
  }
}
.small-container {
  max-width: 1014px;
}

//////
.cursor-pointer {
  cursor: pointer;
}

.justify-content-betwwen {
  justify-content: space-between;
}

.semiBold {
  font-weight: 600 !important;
}
.fontMedium {
  font-weight: 500 !important;
}
.MuiDivider-vertical {
  &.vertical-line {
    height: auto;
    margin: 0 15px;
  }
}
@media screen and (max-width: 767px) {
  .category-outer-container.full-content {
    height: calc(100vh - 56px);
    // overflow: scroll;
  }
  .main-div-in {
    height: calc(100vh - 0px);
    // overflow: scroll;

    .makeStyles-box-4 {
      padding-bottom: 0px;
    }
  }
}

.searchbox {
  max-width: 336px;
  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
  input {
    height: 36px;
  }
}
body {
  .MuiContainer-root {
    max-width: 1076px;
  }

  .MuiContainer-maxWidthLg {
    max-width: 1064px;
  }
}

.ReactModalPortal {
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 9999 !important;
    .ril__inner {
      top: 200px;
      bottom: 80px;
    }
  }
}
