.MobileApp {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 480px;
  width: 100%;
  box-shadow: 0px 0px 20px 0px grey;
  border-radius: 12px;
  background-color: #f5f7f9;
  margin-top: 70px;
  margin-bottom: 20px;
  font-family: 'Helvetica';
}

.paddingHorizentalOldInvoice {
  padding-right: 15px;
  padding-left: 15px;
}

.rows {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.rowStart {
  display: flex;
  flex-direction: column;
  flex: 3;
}

.rowEnd {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 3;
}

.blackboldtext {
  color: black;
  font-weight: 600;
  font-size: 1.4rem;
}

.tableHeaderContainer {
  display: flex;
  justify-content: space-between;
  flex: 1 1 0%;
  color: white;
  font-weight: 600;
  font-size: 1.4rem;
  padding: 16px 3px;
  background-color: rgb(128, 128, 128);
  font-family: Montserrat;
  /* width: fit-content; */
}

.itemWidth {
  width: auto;
}

.paymentLinkContainer {
  padding: 20px 5px;
  border-bottom: 0.5px solid grey;
  border-top: 0.5px solid grey;
  align-items: center;
  color: #000;
}

/************** NEW INVOCIE CSS  *******************/

.NewMobileApp {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 960px;
  width: 100%;
  box-shadow: 0px 0px 10px 0px black;
  background-color: #f5f7f9;
  margin-bottom: 20px;
  position: absolute;
  top: 70px;
  /* height: calc(100vh-100px); */
}

.topHeaderBoxData {
  position: relative;
  top: -70px;
  left: 40px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  width: 35%;
  border-radius: 5px;
  height: 100px;
  padding: 20px;
}

.topHeaderBoxDataText {
  padding: 3px;
}

.headerInvoiceText {
  display: flex;
  flex-direction: column;
  place-content: flex-end;
  align-items: flex-end;
  flex: 3 1 0%;
  margin-bottom: 10px;
  font-size: 30px;
  margin-top: 30px;
}

.newTableHeaderContainer {
  display: flex;
  justify-content: space-between;
  flex: 1 1 0%;
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 14px;
  padding: 16px 3px;
  background-color: rgb(92, 77, 177);
  font-family: Montserrat;
  /* width: fit-content; */
}

.newItemWidth {
  width: auto;
}

@page {
  margin: 0px;
}

.newInvoiceHeaderForPrint {
  visibility: hidden;
  position: absolute;
  top: 30px;
  width: 100%;
}

.printGradientForPrint {
  position: absolute;
  bottom: 0px;
  width: 100%;
  visibility: hidden;
}

/************** PRINT QUERIES  ******************/

@media print {
  @font-face {
    font-family: 'Helvetica' !important;
    src: url('../../assets/fonts/Helvetica.ttf') format('truetype');
  }

  body * {
    visibility: hidden;
    -webkit-print-color-adjust: exact !important;
  }

  @page {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 200px;
    max-height: 300px;
  }

  .topHeaderBoxData {
    position: relative;
    left: var(--left);
    width: 25%;
  }

  .headerInvoiceTextForPrint {
    position: relative;
    right: var(--right);
  }

  #printableArea,
  #printableArea * {
    visibility: visible;
  }

  #printableArea {
    position: absolute;
    left: 20;
    top: 20;
  }
  #newPrintableArea,
  #newPrintableArea * {
    visibility: visible;
  }

  .newInvoiceHeader {
    display: none;
  }

  .MobileApp {
    // padding: initial;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;
    width: 100%;
    box-shadow: 0px 0px 0px 0px black;
    border-radius: 25px;
    background-color: #fff;
    margin-top: 50px;
    font-family: 'Helvetica';
  }

  .NewMobileApp {
    background-color: #fff;
    margin-top: 110px;
  }

  .printGradient {
    display: none;
  }

  .printGradientForPrint {
    position: fixed;
    bottom: 0px;
  }
}

/************** MEDIA QUERIES  ******************/

@media screen {
  @font-face {
    font-family: 'Helvetica' !important;
    src: url('../../assets/fonts/Helvetica.ttf') format('truetype');
  }

  .MobileApp {
    font-family: 'Helvetica';
  }
}

@media only screen and (min-width: 960px) {
  .newItemWidth {
    width: auto;
  }
}

@media only screen and (max-width: 700px) {
  .newTableHeaderContainer {
    width: fit-content;
  }
}

@media only screen and (max-width: 700px) and (min-width: 451px) {
  .NewMobileApp {
    max-width: 450px;
    /* padding: 10px; */
  }

  .tableHeaderContainer {
    width: 100%;
  }

  .rows {
    justify-content: space-around;
  }

  .topHeaderBoxData {
    left: 10px;
    width: 180px;
    height: unset;
    padding: 10px;
  }

  .topHeaderBoxDataText {
    padding: 1px;
    font-size: 11px;
    /* border: 1px solid green; */
  }

  .newTableHeaderContainer {
    width: fit-content;
  }
  .newItemWidth {
    width: auto;
  }

  .TnC_Container {
    font-size: 14px;
  }
}

@media only screen and (max-width: 450px) and (min-width: 340px) {
  .MobileApp {
    max-width: 360px;
  }
  .oldInvoiceTopRow {
    font-size: 12px;
  }

  .blackboldtext {
    font-size: 12px;
  }

  .invoiceDateContainer {
    font-size: 12px;
  }

  .tableHeaderContainer {
    width: fit-content;
  }

  .itemWidth {
    width: auto;
  }

  .paymentLinkContainer {
    font-size: 13px;
  }

  /* NEW INVOCE CSS FROM HERE  */

  .NewMobileApp {
    max-width: 360px;
    /* padding: 10px; */
  }
  .rows {
    justify-content: space-around;
  }

  .topHeaderBoxData {
    left: 10px;
    width: 180px;
    height: unset;
    padding: 10px;
  }

  .topHeaderBoxDataText {
    padding: 1px;
    font-size: 11px;
    /* border: 1px solid green; */
  }

  .newInvoiceCommonFont {
    font-size: 14px;
  }

  .newInvoiceDiscounttext {
    font-size: 14px;
  }

  .newTableHeaderContainer {
    width: fit-content;
  }
  .newItemWidth {
    width: auto;
  }
  .itemListContainer {
    width: fit-content;
  }

  .TnC_Container {
    font-size: 14px;
  }
}

@media only screen and (max-width: 320px) {
  .MobileApp {
    max-width: 320px;
  }

  .blackboldtext {
    font-size: 12px;
  }

  .invoiceDateContainer {
    font-size: 12px;
  }
  .tableHeaderContainer {
    width: fit-content;
  }
  .itemWidth {
    width: auto;
  }

  .itemListContainer {
    width: fit-content;
  }

  .paymentLinkContainer {
    font-size: 12px;
  }

  /* NEW INVOCE CSS FROM HERE  */

  .rows {
    justify-content: space-around;
  }

  .NewMobileApp {
    max-width: 320px;
    /* padding: 5px; */
  }

  .newInvoiceCommonFont {
    font-size: 14px;
  }
  .newInvoiceDiscounttext {
    font-size: 13px;
  }

  .topHeaderBoxData {
    left: 5px;
    width: 180px;
    height: unset;
    padding: 10px;
  }

  .topHeaderBoxDataText {
    padding: 1px;
    font-size: 11px;
    /* border: 1px solid yellow; */
  }

  .headerInvoiceText {
    font-size: 20px;
  }

  .TnC_Container {
    font-size: 14px;
  }
}

.invoiceRoot {
  font-size: 16px;
  .blackboldtext {
    font-size: 14px;
  }
  --left: calc(100px);
  --right: calc(90px);
  // body {
  //   margin: 0;
  //   font-size: 1.6rem;
  //   font-family: 'Montserrat';
  // }
}
